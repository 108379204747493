<template>

  <BCard v-if="$can('read', 'matriculas')">
    <!-- * filtros: CURSO, BUSCAR... -->
    <BRow class="mb-1">
    <!-- * CURSOS -->
      <BCol
          cols="12"
          md="6"
        >
          <BOverlay
            :show="cargandoCursos"
            spinner-variant="primary"
            variant="semi-dark"
          >
            <BFormSelect
              v-model="selectedCurso"
              :options="optionsCursos"
              @change="changeCurso"
            />
          </BOverlay>
        </BCol>
        <!-- * FILTRO -->
        <BCol
          cols="12"
          md="6"
          class="mt-1 mt-md-0"
        >
          
          <inputFiltro
            :filter.sync="filter"
            size="md"
            :cols="false"
          />
        </BCol>
    </BRow>
    <!-- * ESTADISTICAS, BTN CREAR -->
    <BRow>

      <!-- * ESTADÍSTICAS -->
      <BCol
        v-for="item in datosEstudiantes"
        :key="item.icon"
        md="2"
        sm="2"
        class="mb-2 mb-md-0 my-1"
        :class="item.customClass"
      >
        
        <BMedia no-body>
          <BMediaAside
            class="mr-2"
          >
            <BAvatar
              size="48"
              :variant="item.color"
            >
              <FeatherIcon
                size="24"
                :icon="item.icon"
              />
            </BAvatar>
          </BMediaAside>
          <BMediaBody class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ item.title }}
            </h4>
            <BCardText class="font-small-3 mb-0">
              {{ item.subtitle }}
            </BCardText>
          </BMediaBody>
        </BMedia>
      </BCol>

      <!-- * BTN CREAR -->
      <BCol
        md="6"
        sm="6"
        class="my-2"
      >
        <btnCrear
          texto="Matrícula"
          modulo="matriculas"
          @processAdd="goToAddMatricula"
        />
      </BCol>
    </BRow>

    <BOverlay
      :show="cargando_delete"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BTable
        small
        striped
        hover
        noCollapse
        class="mt-1 rounded"
        responsive
        :items="items"
        :fields="fields"
        :busy="cargando"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        
        <!-- Cargando -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <spinner />
          </div>
        </template>

        <!-- Header: Check -->
        <template #head(colCheck)="data">

          <BFormCheckbox
            :id="data.label"
            v-model="chkTodo"
          />

        </template>

        <!-- col: ROL -->
        <template #cell(idrol)="data">
          {{ data.item.id_persona_rol_alumno }}
        </template>

        <!-- col: RUT -->
        <template #cell(rut_completo)="data">
          <estudiante-retirado
            :data.sync="data.item.rut_completo"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_matricula"
            :motivo_retiro.sync="data.item.motivo_retiro"
            :mayus="false"
          />
        </template>

        <!-- col: NOMBRE -->
        <template #cell(nombre_completo)="data">
          <estudiante-retirado
            :data.sync="data.item.nombre_completo"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :motivo_retiro.sync="data.item.motivo_retiro"
            :fecha_ingreso.sync="data.item.fecha_matricula"
            :mayus="true"
          />
        </template>

        <!-- col: CURSO -->
        <template #cell(nombre_curso)="data">
          <estudiante-retirado
            :data.sync="data.item.nombre_curso"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :motivo_retiro.sync="data.item.motivo_retiro"
            :fecha_ingreso.sync="data.item.fecha_matricula"
            :mayus="false"
          />
        </template>

        <!-- col: FECHA MATRICULA -->
        <template #cell(fecha_matricula)="data">
          <estudiante-retirado
            :data.sync="data.item.fecha_matricula"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :motivo_retiro.sync="data.item.motivo_retiro"
            :fecha_ingreso.sync="data.item.fecha_matricula"
            :mayus="false"
          />
        </template>

        <!-- col: FECHA RETIRO -->
        <template #cell(fecha_retiro)="data">
          {{data.item.fecha_retiro}}
        </template>

        <!-- col: ACCIONES -->
        <template #cell(acciones)="data">
          <matriculas-retirar
            :nombreModal="`retirar_${data.item.id}`"
            :item="data.item"
            title="Retirar estudiante"
            @processRetirarEstudiante="retirarEstudiante"
          />
          <!-- {{ data.item }} -->
          <matriculas-acciones
            modulo="matriculas"
            :data="data.item"
            :nombreModalRetirar="`retirar_${data.item.id}`"
            @processGoToUpdate="goToUpdate(data.item)"
          />
        </template>

      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import {
  BTable, BRow, BCol, BFormCheckbox, BOverlay, BCard, BFormSelect, 
  BMedia, BCardText, BAvatar, BMediaAside, BMediaBody
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// MIXINS
import { rut } from '@/mixins/rut'
import { formatos } from '@/mixins/formatos'

// COMPONENTES RECICLADOS
import btnCrear from '../components/List/btnCrear.vue'
import inputFiltro from '../components/List/inputFiltro.vue'
import spinner from '../components/spinner.vue'
import estudianteRetirado from '../components/List/estudianteRetirado.vue'

// COMPONENTES HIJOS
import MatriculasAcciones from './components/MatriculasAcciones.vue'
import MatriculasRetirar from './components/MatriculasRetirar.vue'
import MatriculasCreate from './components/MatriculasCreate.vue'

export default {
  components: {
    BTable, BRow, BCol, BFormCheckbox, BOverlay, BCard, BFormSelect, 
    BMedia, BCardText, BAvatar, BMediaAside, BMediaBody,

    // COMPONENTES RECICLADOS
    btnCrear,
    inputFiltro,
    spinner,
    estudianteRetirado,

    // COMPONENTES HIJOS
    MatriculasCreate,
    MatriculasAcciones,
    MatriculasRetirar,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // reloads
      cargando: false,
      cargandoCursos: true,
      cargando_delete: false,

      // tabla
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],

      // info
      selectedCurso: null,
      cuentaRetirados: 0,
      optionsCursos: [],
      items: [],
      fields: [
        {
          key: 'numero_lista',
          label: 'lista',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '3% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        
        {
          key: 'rut_completo',
          label: 'RUT',
          sortable: true,
          thStyle: {
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '25% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_curso',
          label: 'Curso',
          sortable: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        
        {
          key: 'numero_matricula',
          label: 'Matrícula',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '3% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha_matricula',
          label: 'Fecha Matrícula',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha_retiro',
          label: 'Fecha Retiro',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '5% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getMatriculas: 'matriculas/getMatriculas',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
    }),
    datosEstudiantes() {

      let totalMatriculados = this.items.length - this.cuentaRetirados
      if (typeof this.items.length === 'undefined') {
        totalMatriculados = 0
      }
      const totalEstudiantes = typeof this.items.length === 'undefined' ? 0 : this.items.length
      const totalRetirados = totalEstudiantes - totalMatriculados

      const data = [
        {
          icon: 'UserCheckIcon',
          color: 'light-info',
          title: totalMatriculados,
          subtitle: 'Matriculados',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserMinusIcon',
          color: 'light-secondary',
          title: totalRetirados,
          subtitle: 'Retirados',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: totalEstudiantes,
          subtitle: 'Total',
          customClass: 'mb-2 mb-xl-0',
        },
      ]

      return data
    },
  },
  watch: {
    getMatriculas(val) {
      this.totalRows = val.length
      this.items = []
      this.setMatriculas(this.getMatriculas)
    },
  },
  mounted() {
    this.setTableList()
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchMatriculasEstablecimiento: 'matriculas/fetchMatriculasEstablecimiento',
      updateMatriculaPeriodo: 'matriculas/updateMatriculaPeriodo',
      removeMatriculas: 'matriculas/removeMatriculas',
      retirarMatricula: 'matriculas/retirarMatricula',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
    }),
    ...mapMutations('matriculas', ['setMatricula']),
    async initialized() {
      
    },
    async initialize() {
      
      await Promise.all([
        this.cargarCursos(),
        this.cargarMatriculas()
      ])
    },
    async cargarCursos() {
      if (this.getCursosPersonaRol.length > 1) {
        this.selectedCurso = this.getCursosPersonaRol[0].id
      } else {
        await this.fetchCursosPersonaRol()
        this.selectedCurso = this.getCursosPersonaRol[0].id
      }

      this.getCursosPersonaRol.forEach(curso => {
        const nombreCurso = curso.nombre+' '+curso.letra
        this.optionsCursos.push(
          {
            value: curso.id,
            text: nombreCurso,
          }
        )
      })
      this.cargandoCursos = false
    },
    async cargarMatriculas() {
      this.cargando = true
      await this.fetchMatriculasEstablecimiento(this.selectedCurso)
      this.cargando = false
    },
    
    setTableList() {
      if (this.$can('update', 'matriculas')
        || this.$can('retirar', 'matriculas')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    setMatriculas(matriculas) {
      this.items = []
      this.cuentaRetirados = 0
      for (let i = 0; i < matriculas.length; i++) {
        let matricula = matriculas[i];
        matricula['rut_completo'] = this.formatRut(matricula.rut + '' + matricula.dv)
        matricula['nombre_completo'] = `${matricula.primer_apellido}
            ${matricula.segundo_apellido}
            ${matricula.nombre}
            ${matricula.segundo_nombre !== null
              ? matricula.segundo_nombre
              : ''}
          `
        matricula['nombre_curso'] = matricula.nombre_curso
        matricula['fecha_matricula'] = matricula.fecha_ingreso !== null
          ? this.formatoDiaMesYear(matricula.fecha_ingreso)
          : matricula.fecha_ingreso
        matricula['fecha_retiro'] = matricula.fecha_retiro !== null
          ? this.formatoDiaMesYear(matricula.fecha_retiro)
          : matricula.fecha_retiro
        this.cuentaRetirados = matricula.fecha_retiro !== null
          ? this.cuentaRetirados + 1
          : this.cuentaRetirados
      }
      this.items = matriculas
    },

    async changeCurso() {
      await this.cargarMatriculas()
    },

    goToAddMatricula() {
      this.$router.replace({
        name: 'matriculas-create',
      })
    },
    goToUpdate(matricula) {
      this.setMatricula(matricula)
      this.$router.push({
        name: 'matriculas-update',
      })
    },

    retirarEstudiante(retiro) {
      this.$swal({
        title: 'Retirar estudiante!',
        text: `Estás seguro que deseas retirar al estudiante
          "${retiro.nombreEstudiante}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, retirar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.cargando_delete = true
          this.retirarMatricula(retiro).then(() => {
            const statusMatricula = store.state.matriculas.status
            if (statusMatricula === 'success') {
              const title = 'Estudiante retirado con éxito 👍'
              const text = `"El estudiante ${retiro.nombreEstudiante}" ha sido eliminado!`
              this.$toast({
                component: ToastificationContent,
                props: {
                  title,
                  icon: 'CheckIcon',
                  text,
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 6000,
              })
              this.$bvModal.hide(retiro.nombreModal)
              const item = this.items.find(i => i.id_matricula === retiro.id_matricula)
              item.fecha_retiro = retiro.fecha_retiro
              item.motivo = retiro.motivo
              this.setMatriculas(this.items)
            } else {
              this.$swal({
                title: 'Error!',
                text: store.state.matriculas.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            this.cargando_delete = false
          })
        }
      })
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
